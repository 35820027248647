<template>
  <v-btn
    :class="`${classes}`"
    :disabled="disabled"
    :title="lang('ChecklistBuilder.titles.upload_files')"
    data-quick-button-action-view="upload_files"
    type="button"
    small
  >
    <v-icon>
      mdi-file
    </v-icon>
  </v-btn>
</template>

<script>
export default {
  name: 'ChecklistCellButtonTypeFiles',
  props: [
    'x',
    'y',
    'cell',
    'checklist',
    'state',
    'buttontype',
    'data'
  ],
  computed: {
    dataViewer() {
      const builder = this.data.ChecklistBuilder
      return builder.getCellDataViewer(this.checklist.index, this.x, this.y) || {}
    },
    classes() {
      if (/[0-9]+/.test(this.dataViewer.files) || /[0-9]+/.test(this.cell.files)) {
        return `${this.state.classes} assigned-files`
      }
      return this.state.classes
    },
    disabled() {
      const builder = this.data.ChecklistBuilder
      return this.state.disabled || !builder.getSetting('ONLINE')
    }
  },
  methods: {
    lang(langKey) {
      const lang = this.data.ChecklistBuilder.getSetting('TRANSLATION')
      return lang(langKey)
    }
  }
}
</script>

<style>
  .checklist-viewer .cell-button-collection .assigned-files i {
      color: #0a0;
  }
  .checklist-viewer .cell-button-collection .required-files i {
    color: #f00;
  }
</style>
